<template>
  <v-row class="type-one py-md-5">
    <v-col class="left" cols="12" md="6">
      <div
        class="content"
        :style="[
          $vuetify.breakpoint.smAndDown
            ? { backgroundImage: 'url(\'' + getAsset(imageSrc) + '\')' }
            : { 'background-color': '#000' }
        ]"
      >
        <div class="text">
          <div class="mx-auto">
            <h2 class="text-title" style="color: #0066E3" v-html="title" />
            <p class="text-desc" style="color: #ffffff" v-html="description" />
            <v-btn
              aria-label="詳細はこちら"
              color="primary"
              outlined
              rounded
              large
              class="btn mt-12 py-3 white--text"
              :to="goTo"
            >
              詳細はこちら
            </v-btn>
          </div>
        </div>
      </div>
    </v-col>
    <v-col class="right hidden-sm-and-down" cols="12" md="6">
      <v-img
        height="100%"
        width="100%"
        lazy-src="@/assets/images/lazy-image.png"
        :src="getAsset(imageSrc)"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "TypeOne",
  props: {
    imageSrc: {
      type: String,
      default: "images/image-1.webp"
    },
    title: {
      type: String,
      default: "title"
    },
    description: {
      type: String,
      default: "description"
    },
    goTo: {
      type: String,
      default: "/"
    }
  },
  methods: {
    getAsset(path) {
      return require("@/assets/" + path);
    }
  }
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'
.type-one
  margin: -12px
  .left, .right
    height: 580px
    padding: 12px
  .left
    position: relative
    display: flex
    height: 600px
    .content
      position: relative
      top: 0
      bottom: 0
      left: 0
      right: 0
      height: 100%
      width: 100%
      background-position: center
      background-repeat: no-repeat
      background-size: cover
      .text
        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0
        height: 100%
        width: 100%
        transition: 0.5s ease
        background-color: #00000080
        padding: 12px
        display: flex
        text-align: center
        align-items: center
        margin: 0 auto
        .text-title
          margin-bottom: 32px
  .btn
    text-shadow: none
    box-shadow: none
    &:hover
      border: none
      color: #ffffff !important
      background-color: #0066E3
@media #{map-get($display-breakpoints, 'md-and-up')}
  .type-one
    .left, .right
      padding-top: 0
      padding-bottom: 0
    .left
      height: unset
      .text
        display: flex
        text-align: center
        align-items: center
        width: 100%
        height: 100%
        margin: 0 auto
        .text-title
          margin-bottom: 32px
</style>
