<template>
  <div>
    <v-card flat tile class="hero overflow-hidden">
      <v-img
        class="bg-img"
        lazy-src="@/assets/images/lazy/hero-image.png"
        src="@/assets/images/hero-image.webp"
      />
      <div class="inner-hero">
        <h1 class="mx-auto">
          建設現場のリモート業務支援サービス<br />
          Construction Site Remote Support System<br />
          建設現場と社内を簡単に接続し、現場への十分な業務支援を遠隔で実現します。

          <br />
          「
          <a href="https://www.youtube.com/embed/SlV1-8X8y0E" class='white--text' >CSRSのご紹介ビデオ</a
          >
          」「
          <a href="/pdf/csrs.pdf" download="csrs.pdf" class='white--text' >パンフレット</a>
          」
        </h1>

        <v-btn
          aria-label="詳細はこちら"
          color="primary"
          rounded
          large
          class="mt-7 mt-sm-5 py-3 white--text"
          to="/csrs"
        >
          詳細はこちら
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "HomeHero"
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'
.hero
  height: 85vh
  min-height: 620px
  background-image: url("../assets/images/hero-image.webp")
  background-color: #000000
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  position: relative
  padding-top: 100px
  margin-top: -100px
  .bg-img
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    min-width: 100%
    min-height: 100%
    width: auto
    height: auto
  .inner-hero
    width: 100%
    position: absolute
    text-align: center
    transform: translateX(0%) translateY(50%)
    text-shadow: 0 0 5px rgba(0,0,0,0.8)
    top: -10%
    bottom: 0
    left: 0
    right: 0
    height: 100%
    transition: .5s ease
    background-color: #00000080
    padding: 20px 12px
    h1
      font-style: normal
      font-weight: bold
      font-size: 18px
      color: #ffffff
.btn
  text-shadow: none
  box-shadow: none
  &:hover
    border: none
    color: #ffffff !important
    background-color: #0066E3
@media #{map-get($display-breakpoints, 'sm-only')}
  .hero
    min-height: 500px
    max-height: 600px
    .inner-hero
      padding-top: 50px
@media #{map-get($display-breakpoints, 'md-and-up')}
  .hero
    min-height: 800px
    .inner-hero h1
      font-size: 40px
</style>
