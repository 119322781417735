<template>
  <div id="contact">
    <div class="contact-section">
      <v-img
        height="100%"
        lazy-src="@/assets/images/lazy-image.png"
        src="@/assets/images/image-9.webp"
      >
        <div class="content">
          <h2>お問い合わせ</h2>
          <p>ご意見・ご質問などございましたら、お問い合わせください。</p>
          <p>愛知県名古屋市瑞穂区彌富通2丁目7番地</p>
          <div>
            <a href="mailto: tamasato@jthink.co.jp">tamasato@jthink.co.jp</a>
          </div>
          <p>電話：052-870-3568</p>
          <contact-form />
          <iframe
            title="location"
            class="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d17308.94165331327!2d136.93704904997742!3d35.11582178153742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60037b01cfa0913f%3A0xf0bdf583dc70fcef!2z5pel5pys44CB44CSNDY3LTAwNjQg5oSb55-l55yM5ZCN5Y-k5bGL5biC55Ge56mC5Yy65b2M5a-M6YCa77yS5LiB55uu77yX!5e0!3m2!1sja!2skh!4v1589448209471!5m2!1sja!2skh"
            frameborder="0"
            style="border:0;"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </div>
      </v-img>
    </div>
  </div>
</template>

<script>
import ContactForm from "./ContactForm";
export default {
  name: "ContactSection",
  components: { ContactForm },
  props: {
    imageSrc: {
      type: String,
      default: "images/image-8.webp"
    },
    title: {
      type: String,
      default: "title"
    },
    description: {
      type: String,
      default: "description"
    }
  },
  methods: {
    getAsset(path) {
      return require("@/assets/" + path);
    }
  }
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles.sass'
.contact-section
  position: relative
  .inner
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    height: 100%
    width: 100%
    transition: .5s ease
  .content
    margin: 0 auto
    padding: 32px 12px
    max-width: 780px
    color: white
    font-size: 20px
    text-align: center
    h2, p, div
      margin-bottom: 16px
    a
      text-decoration: none
      color: #ffffff
  .map
    width: 100%
    height: 350px
@media #{map-get($display-breakpoints, 'md-and-up')}
  .contact-section
    position: relative
    .content
      padding: 120px 12px !important
      h2, p, div
        margin-bottom: 32px
    .map
      width: 100%
      height: 350px
</style>
