<template>
  <div class="home">
    <vue-headful
      title="ホーム | CSRSサービス"
      description=""
      keywords="明るい未来のためのエンジニアリング"
    />
    <home-hero />
    <v-container>
      <section-title>サービスご紹介</section-title>
      <type-one
        :image-src="section_2[0].image"
        :title="section_2[0].title"
        :description="section_2[0].description"
        :go-to="section_2[0].goTo"
      />
      <type-one
        :image-src="section_12[0].image"
        :title="section_12[0].title"
        :description="section_12[0].description"
        :go-to="section_12[0].goTo"
        style="flex-direction: row-reverse;"
      />
    </v-container>
    <contact-section />
  </div>
</template>

<script>
import HomeHero from "@/components/HomeHero";
import TypeOne from "@/components/TypeOne";
import ContactSection from "@/components/ContactSection";
import SectionTitle from "../components/SectionTitle";

export default {
  name: "Home",
  components: {
    SectionTitle,
    ContactSection,
    TypeOne,
    HomeHero
  },
  data() {
    return {
      section_2: [
        {
          image: "images/image-5.webp",
          title:
            "Construction Site Remote Support System <br />サービスのご紹介",
          description:
            "本サービスでは、現場と社内を簡単にリモートでつなぎ、現場に必要なサポートができるサービスです" +
            "<br />" +
            "このサービスを活用して、他社との差別化を実現して" +
            "<br />" +
            "現場業務の効率向上を実現しましょう",
          goTo: "/csrs"
        }
      ],
      section_12: [
        {
          image: "images/image-4.webp",
          title: "リモート技術者支援<br />サービスのご紹介",
          description:
            "本サービスは、現場と技術者をリモートでつなぎ、現場に都度必要な技術のサポートができるサービスです" +
            "<br />" +
            "例えば、見積・CAD図作成・３D設計データ作成・現場での施工管理や安全書類作成業務といった技術が必要な業務を遠隔で技術者が時間単位でサポートしてくれるため、効率向上が期待されます" +
            "<br />" +
            "IT技術を駆使して、高齢技術者や健常ではない技術者の方々が活躍できる新たな働き方を創ります。",
          goTo: "/remote-engineer-support-service"
        }
      ]
    };
  }
};
</script>
