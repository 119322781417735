<template>
  <div>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-row>
        <v-col cols="12">
          <v-text-field
            outlined
            color="#fff"
            v-model="name"
            :rules="nameRules"
            label="名前*"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            color="#fff"
            v-model="email"
            :rules="emailRules"
            label="メールアドレス*"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            color="#fff"
            v-model="phone_number"
            :rules="phone_numberRules"
            label="電話番号"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            color="#fff"
            v-model="place_address"
            :rules="addressRules"
            label="住所"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            color="#fff"
            v-model="subject"
            label="件名"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            outlined
            color="#fff"
            v-model="message"
            name="message"
            label="メッセージを入力してください"
            required
          ></v-textarea>
        </v-col>
        <v-col cols="12">
          <v-text-field
            outlined
            color="#fff"
            v-model="enter_url"
            :rules="urlRules"
            label="URL を入力"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-btn
            aria-label="送信"
            x-large
            block
            color="#fff"
            class="btn text-capitalize"
            :disabled="!valid"
            :loading="loading"
            @click="submit"
          >
            <span class="px-12">送信</span>
          </v-btn>
        </v-col>
        <v-col cols="12">
          <div v-if="submitted" class="white--text mt-2 green">
            {{ alert }}
          </div>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
export default {
  name: "ContactForm",
  data: () => ({
    valid: true,
    name: "",
    nameRules: [v => !!v, v => v.length <= 100],
    message: "",
    messageRules: [v => !!v || "お問い合わせ内容を入力してください。"],
    email: "",
    emailRules: [
      v => !!v || "メールアドレスを入力してください。",
      v =>
        // eslint-disable-next-line no-useless-escape
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "メールアドレスに誤りがあります。"
    ],
    phone_number: "",
    phone_numberRules: [],
    place_address: "",
    addressRules: [v => v.length <= 250],
    subject: "",
    enter_url: "",
    // eslint-disable-next-line no-useless-escape
    urlRules: [],
    lazy: false,
    bot: true,
    loading: false,
    submitted: false,
    alert: ""
  }),

  methods: {
    submit() {
      this.$refs.form.validate();
      let self = this;
      self.loading = true;
      self.alert = "";
      self.axios
        .post("https://tm-sending-email.herokuapp.com/api/send-email", {
          name: self.name,
          email: self.email,
          phone_number: self.phone_number,
          place_address: self.place_address,
          subject: self.subject,
          message: self.message,
          enter_url: self.enter_url
        })
        .then(function(response) {
          self.output = response.data;
          self.loading = false;
          self.submitted = true;
          self.alert = "お問合せありがとうございます。メッセージを承りました。";
          self.reset();
          setTimeout(() => {
            self.alert = "";
          }, 5000);
        })
        .catch(function(error) {
          console.log(error.response.data.data)
        });
    },
    reset() {
      this.$refs.form.reset();
    }
  }
};
</script>

<style lang="sass" scoped>
@import '~vuetify/src/styles/styles'
.send-title
    font-weight: bold
    font-size: 30px
    text-align: center
.col
  padding-top: 0
  padding-bottom: 0
</style>
